<template>
  <div class="fixed-btn">
    <div class="abm-save-btn" @click.prevent="save">
        <i class="fa fa-save fa-2x" aria-hidden="true"></i>
    </div>
    <div class="abm-cancel-btn" @click.prevent="cancel">
        <i class="fa fa-close fa-2x" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'abmButtons',
  data() {
    return {
    }
  },
  methods: {
    save() {
      this.$emit('save');
    },
    cancel() {
      this.$emit('cancel');
    }
  },
}
</script>

<style>
</style>
